<template>
    <v-container fluid class="mt-2">
        <v-subheader class="text-h6 font-weight-black">
            システム情報
        </v-subheader>
        <div class="systemTab">
            <v-card class="d-inline-block">
                <v-tabs
                    v-model="tabs"
                    color="black"
                    background-color="grey lighten-3"
                >
                    <v-tab
                        v-for="item in tablist"
                        :value="item.id"
                        :key="item.tab"
                    >
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>
            </v-card>
        </div>
        <v-card>
            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <v-card-title> システム停止 </v-card-title>
                    <v-card-text>
                        <v-row class="mt-0 ml-2" dense>
                            <v-col cols="12">
                                <v-simple-table class="sms-list-table line">
                                    <col style="width: 180px" />
                                    <col style="auto" />
                                    <tr>
                                        <th>稼働ステータス</th>
                                        <td>
                                            <v-radio-group
                                                hide-details=""
                                                class="ml-3 mb-2"
                                                v-model="systemRadio"
                                                mandatory
                                                row
                                            >
                                                <v-radio
                                                    class="mb-2"
                                                    v-for="item in radiolist"
                                                    :label="item.radio"
                                                    :value="item.id"
                                                    :key="item.radio"
                                                ></v-radio>
                                            </v-radio-group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>停止日時</th>
                                        <td>
                                            <div class="mb-0 d-inline-flex">
                                                <input-date-fld
                                                    class="ma-2"
                                                    :class="{
                                                        bgGreyout: scheduleFlg,
                                                    }"
                                                    :date.sync="startYMD"
                                                    :disabled="scheduleFlg"
                                                    :error="
                                                        isError(
                                                            errorStartYMD
                                                        ) ||
                                                        isError(errorFromTo)
                                                    "
                                                />
                                                <vue-timepicker
                                                    class="my-4"
                                                    v-model="startHM"
                                                    format="HH:mm"
                                                    hour-label="時"
                                                    minute-label="分"
                                                    :class="[
                                                        isError(errorStartHM) ||
                                                        isError(errorFromTo)
                                                            ? 'timeErrorFld'
                                                            : 'timeFld',
                                                        {
                                                            bgGreyout:
                                                                scheduleFlg,
                                                        },
                                                    ]"
                                                    placeholder=" "
                                                    minute-interval="10"
                                                    :disabled="scheduleFlg"
                                                >
                                                </vue-timepicker>
                                                <div class="mx-5 mt-6 text-h6">
                                                    ～
                                                </div>
                                                <input-date-fld
                                                    class="ma-2"
                                                    :class="{
                                                        bgGreyout: scheduleFlg,
                                                    }"
                                                    :date.sync="endYMD"
                                                    :disabled="scheduleFlg"
                                                    :error="
                                                        isError(errorEndYMD) ||
                                                        isError(errorFromTo)
                                                    "
                                                />
                                                <vue-timepicker
                                                    class="my-4"
                                                    v-model="endHM"
                                                    format="HH:mm"
                                                    hour-label="時"
                                                    minute-label="分"
                                                    :class="[
                                                        isError(errorEndHM) ||
                                                        isError(errorFromTo)
                                                            ? 'timeErrorFld'
                                                            : 'timeFld',
                                                        {
                                                            bgGreyout:
                                                                scheduleFlg,
                                                        },
                                                    ]"
                                                    placeholder=" "
                                                    minute-interval="10"
                                                    :disabled="scheduleFlg"
                                                >
                                                </vue-timepicker>
                                            </div>
                                            <div class="mb-3">
                                                <div
                                                    class="
                                                        controll-error
                                                        d-flex
                                                        ml-10
                                                    "
                                                    v-show="
                                                        isError(errorStartYMD)
                                                    "
                                                >
                                                    <span
                                                        v-html="
                                                            dispError(
                                                                errorStartYMD
                                                            )
                                                        "
                                                    ></span>
                                                </div>
                                                <div
                                                    class="
                                                        controll-error
                                                        d-flex
                                                        ml-10
                                                    "
                                                    v-show="
                                                        isError(errorStartHM)
                                                    "
                                                >
                                                    <span
                                                        v-html="
                                                            dispError(
                                                                errorStartHM
                                                            )
                                                        "
                                                    ></span>
                                                </div>
                                                <div
                                                    class="
                                                        controll-error
                                                        d-flex
                                                        ml-10
                                                    "
                                                    v-show="
                                                        isError(errorEndYMD)
                                                    "
                                                >
                                                    <span
                                                        v-html="
                                                            dispError(
                                                                errorEndYMD
                                                            )
                                                        "
                                                    ></span>
                                                </div>
                                                <div
                                                    class="
                                                        controll-error
                                                        d-flex
                                                        ml-10
                                                    "
                                                    v-show="isError(errorEndHM)"
                                                >
                                                    <span
                                                        v-html="
                                                            dispError(
                                                                errorEndHM
                                                            )
                                                        "
                                                    ></span>
                                                </div>
                                                <div
                                                    class="
                                                        controll-error
                                                        d-flex
                                                        ml-10
                                                    "
                                                    v-show="
                                                        isError(errorFromTo)
                                                    "
                                                >
                                                    <span
                                                        v-html="
                                                            dispError(
                                                                errorFromTo
                                                            )
                                                        "
                                                    ></span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>停止メッセージ</th>
                                        <td>
                                            <div class="message-area">
                                                <v-textarea
                                                    v-model="teishiMessage"
                                                    class="ma-3"
                                                    :class="{
                                                        bgGreyout: teishiMsgFlg,
                                                    }"
                                                    background-color="white"
                                                    hide-details="auto"
                                                    outlined
                                                    :disabled="teishiMsgFlg"
                                                    :error="
                                                        isError(
                                                            errorTeishiMessage
                                                        )
                                                    "
                                                ></v-textarea>
                                            </div>
                                            <div
                                                class="
                                                    controll-error
                                                    d-flex
                                                    ml-4
                                                    mb-3
                                                "
                                                v-show="
                                                    isError(errorTeishiMessage)
                                                "
                                            >
                                                <span
                                                    v-html="
                                                        dispError(
                                                            errorTeishiMessage
                                                        )
                                                    "
                                                ></span>
                                            </div>
                                        </td>
                                    </tr>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-card-title> パスワード変更 </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            prepend-icon="mdi-lock"
                            :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            class="passCngArea"
                            maxlength="10"
                            outlined
                            required
                            row-height="3"
                            :error="isError(errorPassword)"
                        ></v-text-field>
                        <div
                            class="controll-error d-flex ml-9 mb-3"
                            v-show="isError(errorPassword)"
                        >
                            <span v-html="dispError(errorPassword)"></span>
                        </div>
                    </v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-card-title> メールアドレス </v-card-title>
                    <v-card-text class="mb-5">
                        <template v-for="(mail, index) in arrMails">
                            <v-row :key="index">
                            <div  class="d-flex d-inline-flex align-center mb-3">
                                    <div style="width:150px">
                                    メールアドレス:{{ index + 1 }}
                                    </div>
                                    <v-text-field
                                        solo
                                        v-model="arrMails[index]"
                                        maxlength="40"
                                        outlined
                                        hide-details="auto"
                                        :error-messages="errMail(index)"
                                        dense
                                        class="mail"
                                        :error="isErrMail(index)"
                                    />
                            </div>
                            </v-row>
                        </template>
                           <v-row> 
                               <div v-show="arrMails.length<10">
                                <div @click="clickAddMail" style="margin-left:150px" class="mt-3 d-flex d-inline-flex">
                                    <v-icon color="blue">mdi-plus-circle-outline</v-icon>
                                        <div class="text-caption">メールアドレスを追加する </div>
                                    </div>
                               </div>
                           </v-row>
                    </v-card-text>

                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-btn class="btn-edit mt-3" @click="clickSave()" large>保存</v-btn>
    </v-container>
</template>
<style lang="scss" scope>
.message-area {
    width: 780px;
}
.v-tab--active {
    background: silver;
    border-radius: 5px !important;
}
.scheduleSub {
    font-size: 15px;
}
.timeFld {
    input {
        width: 130px !important;
        border: 1px solid rgba(0, 0, 0, 0.38) !important;
        border-radius: 5px !important;
        padding: 19px 10px 20px !important;
        margin-left: 0;
        margin-bottom: 2px;
        font-size: 16px !important;
        color: rgba(0, 0, 0, 0.87);
    }
    .clear-btn {
        padding: 22px 17px !important;
        .char {
            font-size: 20pt !important;
            color: rgba(0, 0, 0, 0.54) !important;
        }
    }
}
.timeErrorFld {
    input {
        width: 130px !important;
        border: 2px solid #ff5252 !important;
        border-radius: 5px !important;
        padding: 18px 10px 19px !important;
        margin-left: 0;
        margin-bottom: 2px;
        font-size: 16px !important;
    }
    .clear-btn {
        padding: 22px 17px !important;
        .char {
            font-size: 20pt !important;
            color: rgb(255, 82, 82) !important;
        }
    }
}
.passCngArea {
    width: 500px;
}
.scheduleMessage {
    margin: 8px 0 0 8px;
}
.bgGreyout .v-input__slot,
.bgGreyout input {
    background: #e4e4e4 !important;
    border-color: rgb(173, 173, 173) !important;
    color: rgba(0, 0, 0, 0.38) !important;
    cursor: default !important;
}
/*inline-blockの下余白を消す*/
.systemTab {
    font-size: 0px;
}
</style>
<script>
import moment from "moment";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import InputDateFld from "../components/InputDateFld.vue";
import { MESSAGE } from "../messages";
export default {
    name: "System",
    components: { VueTimepicker, InputDateFld },
    data() {
        return {
            //タブ
            tabs: "",
            //タブリスト
            tablist: ([] = [
                { id: 0, tab: "稼働状況" },
                { id: 1, tab: "ログイン情報" },
                { id: 2, tab: "メールアドレス" },
            ]),

            //////////////////////////////////////
            /////////システムエリア データ/////////
            //////////////////////////////////////

            //ラジオボタン
            systemRadio: "",
            //ラジオボタンリスト
            radiolist: ([] = [
                { id: 0, radio: "稼働" },
                { id: 1, radio: "停止" },
                { id: 2, radio: "時間指定" },
            ]),
            fromDate: "", //開始日 月日/時分 結合
            toDate: "", //終了日 月日/時分 結合
            startYMD: "", //開始月日
            startHM: { HH: "", mm: "" }, //開始時分
            endYMD: "", //終了月日
            endHM: { HH: "", mm: "" }, //終了時分
            teishiMessage: "", //停止メッセージ
            errorStartYMD: [], //開始月日 エラー
            errorStartHM: [], //開始時分 エラー
            errorEndYMD: [], //終了月日 エラー
            errorEndHM: [], //終了時分 エラー
            errorTeishiMessage: [], //停止メッセージ エラー
            errorFromTo: [], //ToよりFromの超過チェック

            ///////////////////////////////////////
            /////////パスワードエリア データ/////////
            ///////////////////////////////////////

            showPassword: false, //パスワード暗号 表示・非表示
            password: "", //パスワード入力
            errorPassword: [], //パスワード入力 エラー

            ///////////////////////////////////////
            ///////メールアドレスエリア データ///////
            ///////////////////////////////////////
            arrMails: [],
            mail1: "",
            mail2: "",
            mail3: "",
            err: ({} = {
                mail:[],
                mail1: [],
                mail2: [],
                mail3: [],
            }),
        };
    },
    mounted: function () {
        //抽出データ表示
        this.getSystem();
        this.getPassword();
        this.getMail();
    },
    computed: {
        errMail(){
            return (no)=>{
                return this.err.mail[no];
            }
        },
        isErrMail(){
            return (no)=>{
                return this.err.mail[no].length>0?true:false;
            }
        },
        //「時間指定」ボタンが非アクティブの場合、disabled
        scheduleFlg() {
            this.errorStartYMD =
                this.errorStartHM =
                this.errorEndYMD =
                this.errorEndHM =
                this.errorFromTo =
                    [];
            if (this.systemRadio == 2) {
                return false;
            } else {
                return true;
            }
        },
        //「停止」「時間指定」停止メッセージが非アクティブの場合、disabled
        teishiMsgFlg() {
            this.errorTeishiMessage = [];
            if (this.systemRadio != 0) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        //保存ボタン
        clickSave() {
            switch (this.tabs) {
                //「稼働状況」タブ 保存
                case 0:
                    this.saveSystem();
                    break;
                //「ログイン情報」タブ 保存
                case 1:
                    this.passChange();
                    break;
                case 2:
                    this.saveMail();
                    break;
                default:
            }
        },
        ////////////////////////////////////
        /////////システムエリア 関数/////////
        ////////////////////////////////////

        //「時間指定」のデータ抽出
        getSystem() {
            this.axios.get("/api/system").then((res) => {
                this.startYMD = this.endYMD = "";
                this.startHM = this.endHM = { HH: "", mm: "" };
                this.systemRadio = res.data.systemInfo.system_status;
                this.teishiMessage = res.data.systemInfo.message;

                if (this.systemRadio == 2) {
                    var getDateStart = moment(
                        res.data.systemInfo.mainte_start_date
                    );
                    var getDateEnd = moment(
                        res.data.systemInfo.mainte_end_date
                    );

                    this.startYMD = getDateStart.format("YYYY-MM-DD");
                    this.startHM = {
                        HH: getDateStart.format("HH"),
                        mm: getDateStart.format("mm"),
                    };
                    this.endYMD = getDateEnd.format("YYYY-MM-DD");
                    this.endHM = {
                        HH: getDateEnd.format("HH"),
                        mm: getDateEnd.format("mm"),
                    };
                }
            });
        },
        //「稼働状況」タブ 保存処理
        async saveSystem() {
            //「停止」 停止メッセージの空チェック
            this.errorTeishiMessage = [];
            if (this.systemRadio == 1 && !this.teishiMessage) {
                this.errorTeishiMessage = [
                    MESSAGE.formatByArr(MESSAGE.E001, "停止メッセージ"),
                ];
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            //「時間指定」 エラーチェック
            if (this.systemRadio == 2) {
                if (await this.inputCheck()) {
                    this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                    return;
                }
            }

            //各ラジオの確認アラート・登録完了メッセージの文言分け
            if (this.systemRadio == 0) {
                var confVal = "稼働";
            } else if (this.systemRadio == 1) {
                var confVal = "停止";
            } else {
                var confVal = "停止時間を設定";
            }

            //確認アラート
            const confSysMsg = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R004, confVal)
            );

            //”いいえ” の場合、処理終了
            if (!confSysMsg) {
                return;
            }

            if (this.systemRadio != 2) {
                //稼働・停止の場合、停止日時は不要のため null/空文字
                this.fromDate = null;
                this.toDate = null;
                this.startYMD = "";
                this.startHM = { HH: "", mm: "" };
                this.endYMD = "";
                this.endHM = { HH: "", mm: "" };
            }
            //Postデータ格納
            var postData = {
                system_status: this.systemRadio,
                mainte_start_date: this.fromDate,
                mainte_end_date: this.toDate,
                message: this.teishiMessage,
            };

            //登録
            return await this.axios
                .put(`/api/system/1`, postData)
                .then((res) => {
                    //登録完了メッセージ
                    this.swalToast(MESSAGE.formatByArr(MESSAGE.I004, confVal));
                });
        },
        //日付・メッセージ チェック
        async inputCheck() {
            //エラー変数の初期化
            this.errorStartYMD =
                this.errorStartHM =
                this.errorEndYMD =
                this.errorEndHM =
                this.errorFromTo =
                this.errorTeishiMessage =
                    [];
            this.fromDate = this.toDate = "";

            //空チェック・正規表現チェック
            this.errorStartYMD = this.checkYmdErr(this.startYMD, "開始日");
            this.errorStartHM = this.checkHmErr(this.startHM, "開始時間");
            this.errorEndYMD = this.checkYmdErr(this.endYMD, "終了日");
            this.errorEndHM = this.checkHmErr(this.endHM, "終了時間");
            this.errorTeishiMessage =
                this.teishiMessage === ""
                    ? [MESSAGE.formatByArr(MESSAGE.E001, "停止メッセージ")]
                    : [];

            if (
                this.errorStartYMD.length > 0 ||
                this.errorStartHM.length > 0 ||
                this.errorEndYMD.length > 0 ||
                this.errorEndHM.length > 0 ||
                this.errorTeishiMessage.length > 0
            ) {
                return true;
            }

            // 月日・時分 結合
            this.fromDate =
                this.startYMD + " " + this.startHM.HH + ":" + this.startHM.mm;
            this.toDate =
                this.endYMD + " " + this.endHM.HH + ":" + this.endHM.mm;

            //ToよりFromの日付が超えてないかチェック
            if (!this.dateFromToCheck(this.fromDate, this.toDate)) {
                this.errorFromTo = [
                    MESSAGE.formatByArr(MESSAGE.E005, `終了日時`),
                ];
                return true;
            }

            return false;
        },
        //月日エラーチェック関数
        checkYmdErr(ymd, val) {
            //空チェック
            if (ymd === "") {
                return [MESSAGE.formatByArr(MESSAGE.E001, val)];
            } else {
                //正規表現チェック
                if (!moment(ymd, "YYYY-MM-DD", true).isValid()) {
                    return [MESSAGE.formatByArr(MESSAGE.E001, val)];
                }
            }
            return [];
        },
        //時分エラーチェック関数
        checkHmErr(hm, val) {
            //空チェック
            if (hm.HH === "" && hm.mm === "") {
                return [MESSAGE.formatByArr(MESSAGE.E001, val)];
            } else {
                //正規表現チェック
                var hhmm = hm.HH + ":" + hm.mm;
                if (!moment(hhmm, "HH:mm", true).isValid()) {
                    return [MESSAGE.formatByArr(MESSAGE.E001, `${val} 数字`)];
                }
            }
            return [];
        },
        //////////////////////////////////////
        /////////パスワードエリア 関数/////////
        /////////////////////////////////////

        //既存パスワードのデータ抽出
        getPassword() {
            this.axios.get("/api/admin-get-password").then((res) => {
                this.password = res.data.password;
            });
        },
        //パスワード変更
        async passChange() {
            //パスワード変更エラーチェック
            if (await this.passCheck()) {
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }
            //「パスワードを変更しますか？」確認
            const confPassMsg = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R004, "パスワードを変更")
            );

            //”いいえ” の場合、処理終了
            if (!confPassMsg) {
                return;
            }

            //Postデータ格納
            var passData = {
                password: this.password,
            };

            //パスワード変更処理
            return await this.axios
                .post(`/api/admin-change-password`, passData)
                .then((res) => {
                    //パスワード変更完了メッセージ
                    this.swalToast(
                        MESSAGE.formatByArr(MESSAGE.I004, "パスワードを変更")
                    );
                });
        },
        //パスワード変更エラーチェック関数
        passCheck() {
            this.errorPassword = [];

            //パスワード入力が空の場合、エラー表示
            if (this.password === "") {
                this.errorPassword = [
                    MESSAGE.formatByArr(MESSAGE.E001, "変更するパスワード"),
                ];
                return true;
                //半角英数記号チェック
            } else {
                if (!this.isHanEisuKigo(this.password)) {
                    this.errorPassword = [MESSAGE.E003];
                    return true;
                }
            }

            return false;
        },

        //////////////////////////////////////
        ////////メールアドレスエリア 関数///////
        /////////////////////////////////////
        getMail() {
            this.axios.get("/api/system").then((res) => {
                this.arrMails = res.data.mailaddressList;
                this.arrMails.forEach(e => {
                   this.err.mail.push(''); 
                });
            });
        },
        clickAddMail(){
            this.arrMails.push('');
            this.err.mail.push('');
        },
        async saveMail() {
            if (this.mailCheck()) {
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }
            //「メールアドレスを変更しますか？」確認
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R004, "メールアドレスを変更")
            );

            //”いいえ” の場合、処理終了
            if (!res) {
                return;
            }
            //Postデータ格納
            let postData = {mail:this.arrMails };
            this.axios.post("/api/admin-update-mail", postData).then((res) => {
                this.swalToast(
                    MESSAGE.formatByArr(MESSAGE.I004, "メールアドレスを変更")
                );
            });
        },
        mailCheck() {
            //clear mail errors
            for(let i=0; i< this.err.mail.length; i++){
                this.$set(this.err.mail,i,[])
                //this.err.mail[i] = []; 
            }
            for(let i=0; i< this.err.mail.length; i++){

                if(i==0){
                    if(!this.arrMails[i]){
                        this.$set(this.err.mail,i, [ MESSAGE.formatByArr(MESSAGE.E001, `メールアドレス`), ]);
                        continue;
                    }
                }
                if (this.arrMails[i]!== "" && !this.isMailaddress(this.arrMails[i])) {
                    this.$set(this.err.mail, i, [
                        MESSAGE.formatByArr(MESSAGE.E005, "メールドレスの形式"),
                    ]);
                }
            }


            for (let key in this.err.mail) {
                if (this.err.mail[key].length > 0) return true;
            }
            return false;

        },
    },
};
</script>

